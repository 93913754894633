// Background colors

// Purple
/*#region*/
.bg-purple-100 {
  background-color: rgba(237, 233, 254, 1) !important;
}
.bg-purple-200 {
  background-color: rgba(221, 214, 254, 1) !important;
}
.bg-purple-300 {
  background-color: rgba(196, 181, 253, 1) !important;
}
.bg-purple-400 {
  background-color: rgba(167, 139, 250, 1) !important;
}
.bg-purple-500 {
  background-color: rgba(139, 92, 246, 1) !important;
}
.bg-purple-600 {
  background-color: rgba(124, 58, 237, 1) !important;
}
.bg-purple-700 {
  background-color: rgba(109, 40, 217, 1) !important;
}
.bg-purple-800 {
  background-color: rgba(91, 33, 182, 1) !important;
}
.bg-purple-900 {
  background-color: rgba(76, 29, 149, 1) !important;
}
/*#endregion*/

// Indigo
/*#region*/
.bg-indigo-100 {
  background-color: rgba(224, 231, 255, 1) !important;
}
.bg-indigo-200 {
  background-color: rgba(199, 210, 254, 1) !important;
}
.bg-indigo-300 {
  background-color: rgba(165, 180, 252, 1) !important;
}
.bg-indigo-400 {
  background-color: rgba(129, 140, 248, 1) !important;
}
.bg-indigo-500 {
  background-color: rgba(99, 102, 241, 1) !important;
}
.bg-indigo-600 {
  background-color: rgba(79, 70, 229, 1) !important;
}
.bg-indigo-700 {
  background-color: rgba(67, 56, 202, 1) !important;
}
.bg-indigo-800 {
  background-color: rgba(55, 48, 163, 1) !important;
}
.bg-indigo-900 {
  background-color: rgba(49, 46, 129, 1) !important;
}
/*#endregion*/

// Blue
/*#region*/
.bg-blue-100 {
  background-color: rgba(219, 234, 254, 1) !important;
}
.bg-blue-200 {
  background-color: rgba(191, 219, 254, 1) !important;
}
.bg-blue-300 {
  background-color: rgba(147, 197, 253, 1) !important;
}
.bg-blue-400 {
  background-color: rgba(96, 165, 250, 1) !important;
}
.bg-blue-500 {
  background-color: rgba(59, 130, 246, 1) !important;
}
.bg-blue-600 {
  background-color: rgba(37, 99, 235, 1) !important;
}
.bg-blue-700 {
  background-color: rgba(29, 78, 216, 1) !important;
}
.bg-blue-800 {
  background-color: rgba(30, 64, 175, 1) !important;
}
.bg-blue-900 {
  background-color: rgba(30, 58, 138, 1) !important;
}
/*#endregion*/

// Green
/*#region*/
.bg-green-100 {
  background-color: #dcfce7 !important;
}
.bg-green-200 {
  background-color: #bbf7d0 !important;
}
.bg-green-300 {
  background-color: #86efac !important;
}
.bg-green-400 {
  background-color: #4ade80 !important;
}
.bg-green-500 {
  background-color: #22c55e !important;
}
.bg-green-600 {
  background-color: #16a34a !important;
}
.bg-green-700 {
  background-color: #15803d !important;
}
.bg-green-800 {
  background-color: #166534 !important;
}
.bg-green-900 {
  background-color: #14532d !important;
}
/*#endregion*/

// Yellow
/*#region*/
.bg-yellow-100 {
  background-color: #fef9c3 !important;
}
.bg-yellow-200 {
  background-color: #fef08a !important;
}
.bg-yellow-300 {
  background-color: #fde047 !important;
}
.bg-yellow-400 {
  background-color: #facc15 !important;
}
/*#endregion*/

// Red
/*#region*/
.bg-red-100 {
  background-color: rgba(254, 226, 226, 1) !important;
}
.bg-red-200 {
  background-color: rgba(254, 202, 202, 1) !important;
}
.bg-red-300 {
  background-color: rgba(252, 165, 165, 1) !important;
}
.bg-red-400 {
  background-color: rgba(248, 113, 113, 1) !important;
}
.bg-red-500 {
  background-color: rgba(239, 68, 68, 1) !important;
}
.bg-red-600 {
  background-color: rgba(220, 38, 38, 1) !important;
}
.bg-red-700 {
  background-color: rgba(185, 28, 28, 1) !important;
}
.bg-red-800 {
  background-color: rgba(153, 27, 27, 1) !important;
}
.bg-red-900 {
  background-color: rgba(220, 38, 38, 1) !important;
}
/*#endregion*/

// Gray
/*#region*/
.bg-gray-100 {
  background-color: rgba(156, 163, 175, 1) !important;
}
.bg-gray-200 {
  background-color: rgba(156, 163, 175, 1) !important;
}
.bg-gray-300 {
  background-color: rgba(156, 163, 175, 1) !important;
}
.bg-gray-400 {
  background-color: rgba(156, 163, 175, 1) !important;
}
.bg-gray-500 {
  background-color: rgba(107, 114, 128, 1) !important;
}
.bg-gray-600 {
  background-color: rgba(75, 85, 99, 1) !important;
}
.bg-gray-700 {
  background-color: rgba(75, 85, 99, 1) !important;
}
.bg-gray-800 {
  background-color: rgba(75, 85, 99, 1) !important;
}
.bg-gray-900 {
  background-color: rgba(75, 85, 99, 1) !important;
}
/*#endregion*/

// Text colors

// Purple
/*#region*/
.text-purple-100,
.icon-purple-100 i {
  color: rgba(237, 233, 254, 1) !important;
}
.text-purple-200,
.icon-purple-200 i {
  color: rgba(221, 214, 254, 1) !important;
}
.text-purple-300,
.icon-purple-300 i {
  color: rgba(196, 181, 253, 1) !important;
}
.text-purple-400,
.icon-purple-400 i {
  color: rgba(167, 139, 250, 1) !important;
}
.text-purple-500,
.icon-purple-500 i {
  color: rgba(139, 92, 246, 1) !important;
}
.text-purple-600,
.icon-purple-600 i {
  color: rgba(124, 58, 237, 1) !important;
}
.text-purple-700,
.icon-purple-700 i {
  color: rgba(109, 40, 217, 1) !important;
}
.text-purple-800,
.icon-purple-800 i {
  color: rgba(91, 33, 182, 1) !important;
}
.text-purple-900,
.icon-purple-900 i {
  color: rgba(76, 29, 149, 1) !important;
}
/*#endregion*/

// Indigo
/*#region*/
.text-indigo-100,
.icon-indigo-100 i {
  color: rgba(224, 231, 255, 1) !important;
}
.text-indigo-200,
.icon-indigo-200 i {
  color: rgba(199, 210, 254, 1) !important;
}
.text-indigo-300,
.icon-indigo-300 i {
  color: rgba(165, 180, 252, 1) !important;
}
.text-indigo-400,
.icon-indigo-400 i {
  color: rgba(129, 140, 248, 1) !important;
}
.text-indigo-500,
.icon-indigo-500 i {
  color: rgba(99, 102, 241, 1) !important;
}
.text-indigo-600,
.icon-indigo-600 i {
  color: rgba(79, 70, 229, 1) !important;
}
.text-indigo-700,
.icon-indigo-700 i {
  color: rgba(67, 56, 202, 1) !important;
}
.text-indigo-800,
.icon-indigo-800 i {
  color: rgba(55, 48, 163, 1) !important;
}
.text-indigo-900,
.icon-indigo-900 i {
  color: rgba(49, 46, 129, 1) !important;
}
/*#endregion*/

// Blue
/*#region*/
.text-blue-100,
.icon-blue-100 i {
  color: rgba(219, 234, 254, 1) !important;
}
.text-blue-200,
.icon-blue-200 i {
  color: rgba(191, 219, 254, 1) !important;
}
.text-blue-300,
.icon-blue-300 i {
  color: rgba(147, 197, 253, 1) !important;
}
.text-blue-400,
.icon-blue-400 i {
  color: rgba(96, 165, 250, 1) !important;
}
.text-blue-500,
.icon-blue-500 i {
  color: rgba(59, 130, 246, 1) !important;
}
.text-blue-600,
.icon-blue-600 i {
  color: rgba(37, 99, 235, 1) !important;
}
.text-blue-700,
.icon-blue-700 i {
  color: rgba(29, 78, 216, 1) !important;
}
.text-blue-800,
.icon-blue-800 i {
  color: rgba(30, 64, 175, 1) !important;
}
.text-blue-900,
.icon-blue-900 i {
  color: rgba(30, 58, 138, 1) !important;
}
/*#endregion*/

// Green
/*#region*/
.text-green-100,
.icon-green-100 i {
  color: #dcfce7 !important;
}
.text-green-200,
.icon-green-200 i {
  color: #bbf7d0 !important;
}
.text-green-300,
.icon-green-300 i {
  color: #86efac !important;
}
.text-green-400,
.icon-green-400 i {
  color: #4ade80 !important;
}
.text-green-500,
.icon-green-500 i {
  color: #22c55e !important;
}
.text-green-600,
.icon-green-600 i {
  color: #16a34a !important;
}
.text-green-700,
.icon-green-700 i {
  color: #15803d !important;
}
.text-green-800,
.icon-green-800 i {
  color: #166534 !important;
}
.text-green-900,
.icon-green-900 i {
  color: #14532d !important;
}
/*#endregion*/

// Red
/*#region*/
.text-red-100,
.icon-red-100 i {
  color: rgba(254, 226, 226, 1) !important;
}
.text-red-200,
.icon-red-200 i {
  color: rgba(254, 202, 202, 1) !important;
}
.text-red-300,
.icon-red-300 i {
  color: rgba(252, 165, 165, 1) !important;
}
.text-red-400,
.icon-red-400 i {
  color: rgba(248, 113, 113, 1) !important;
}
.text-red-500,
.icon-red-500 i {
  color: rgba(239, 68, 68, 1) !important;
}
.text-red-600,
.icon-red-600 i {
  color: rgba(220, 38, 38, 1) !important;
}
.text-red-700,
.icon-red-700 i {
  color: rgba(185, 28, 28, 1) !important;
}
.text-red-800,
.icon-red-800 i {
  color: rgba(153, 27, 27, 1) !important;
}
.text-red-900,
.icon-red-900 i {
  color: rgba(220, 38, 38, 1) !important;
}
/*#endregion*/

// Gray
/*#region*/
.text-gray-100,
.icon-gray-100 i {
  color: rgba(156, 163, 175, 1) !important;
}
.text-gray-200,
.icon-gray-200 i {
  color: rgba(156, 163, 175, 1) !important;
}
.text-gray-300,
.icon-gray-300 i {
  color: rgba(156, 163, 175, 1) !important;
}
.text-gray-400,
.icon-gray-400 i {
  color: rgba(156, 163, 175, 1) !important;
}
.text-gray-500,
.icon-gray-500 i {
  color: rgba(107, 114, 128, 1) !important;
}
.text-gray-600,
.icon-gray-600 i {
  color: rgba(75, 85, 99, 1) !important;
}
.text-gray-700,
.icon-gray-700 i {
  color: rgba(75, 85, 99, 1) !important;
}
.text-gray-800,
.icon-gray-800 i {
  color: rgba(75, 85, 99, 1) !important;
}
.text-gray-900,
.icon-gray-900 i {
  color: rgba(75, 85, 99, 1) !important;
}
/*#endregion*/
