.cal-month-view .cal-cell-row:hover {
    background-color: #f0f3f5;
}
.cal-month-view .cal-cell-row .cal-cell:hover,
.cal-month-view .cal-cell.cal-has-events.cal-open {
    background-color: #f0f3f5;
}
.cal-month-view .cal-days {
    border: 1px solid #c8ced3;
    border-bottom: 0;
}
.cal-month-view .cal-day-cell:not(:last-child) {
    border-right: 1px solid #c8ced3;
}
.cal-month-view .cal-days .cal-cell-row {
    border-bottom: 1px solid #c8ced3;
}
.cal-month-view .cal-event {
    background-color: #1e90ff;
    border-color: #d1e8ff;
    color: #fff;
}
.cal-month-view .cal-day-cell.cal-weekend .cal-day-number {
    color: #8b0000;
}
.cal-month-view .cal-day-cell.cal-today {
    background-color: #e8fde7;
}
.cal-month-view .cal-day-cell.cal-drag-over {
    background-color: #e1e7eb !important;
}
.cal-month-view .cal-open-day-events {
    color: #fff;
    background-color: #73818f;
    box-shadow: inset 0 0 15px 0 rgba(0, 0, 0, 0.5);
}
.cal-month-view .cal-event-title {
    color: #fff;
}
.cal-week-view .cal-day-headers {
    border: 1px solid #c8ced3;
}
.cal-week-view .cal-day-headers .cal-header:not(:last-child) {
    border-right: 1px solid #c8ced3;
}
.cal-week-view .cal-day-headers .cal-header:first-child {
    border-left: 1px solid #c8ced3;
}
.cal-week-view .cal-day-headers .cal-drag-over,
.cal-week-view .cal-day-headers .cal-header:hover {
    background-color: #f0f3f5;
}
.cal-week-view .cal-day-column {
    border-left: 1px solid #c8ced3;
}
.cal-week-view .cal-event {
    background-color: #d1e8ff;
    border: 1px solid #1e90ff;
    color: #1e90ff;
}
.cal-week-view .cal-all-day-events {
    border-left: 1px solid #c8ced3;
    border-right: 1px solid #c8ced3;
    border-top: 0;
    border-bottom: 3px solid #c8ced3;
}
.cal-week-view .cal-all-day-events .cal-starts-within-week .cal-event {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}
.cal-week-view .cal-all-day-events .cal-ends-within-week .cal-event {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}
.cal-week-view .cal-header.cal-today {
    background-color: #aadfbd;
}
.cal-week-view .cal-header.cal-weekend span {
    color: #f86c6b;
}
.cal-week-view .cal-time-events {
    border: 1px solid #c8ced3;
    border-top: 0;
}
.cal-week-view .cal-time-events .cal-day-columns:not(.cal-resize-active) .cal-hour-segment:hover {
    background-color: #f0f3f5;
}
.cal-week-view .cal-hour-odd {
    background-color: #fafafa;
}
.cal-week-view .cal-drag-over .cal-hour-segment {
    background-color: #f0f3f5;
}
.cal-week-view .cal-hour-segment,
.cal-week-view mwl-calendar-week-view-hour-segment {
    display: block;
}
.cal-week-view .cal-hour:last-child :not(:last-child) .cal-hour-segment,
.cal-week-view .cal-hour:not(:last-child) .cal-hour-segment {
    border-bottom: thin dashed #c8ced3;
}
.cal-day-view .cal-hour-rows {
    border: 1px solid #c8ced3;
}
.cal-day-view .cal-hour:nth-child(odd) {
    background-color: #f0f3f5;
}
.cal-day-view .cal-hour:last-child :not(:last-child) .cal-hour-segment,
.cal-day-view .cal-hour:not(:last-child) .cal-hour-segment {
    border-bottom: thin dashed #c8ced3;
}
.cal-day-view .cal-drag-over .cal-hour-segment,
.cal-day-view .cal-hour-segment:hover {
    background-color: #e4e7ea;
}
.cal-day-view .cal-event {
    background-color: #d1e8ff;
    border: 1px solid #1e90ff;
    color: #1e90ff;
}
