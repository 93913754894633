@import "@coreui/coreui-pro/scss/themes/dark/coreui-dark";

//Cards
.card-shadow {
  box-shadow: 0px 0px 4px 4px #444444;
  border: 1px solid #505152;
}
.roles-card {
  background-color: #2f353a;
}
// Modal
.modal-theme .modal-header {
  color: #fff;
  background-color: #2f353a;
}
// NgSelect
.ng-select-container {
  background-color: #515b65 !important;
  border: 1px solid #23282c !important;

  .ng-input input {
    color: #e4e7ea !important;
  }
}
.ng-value {
  background-color: #454e57 !important;
  border: 1px solid #181b1e !important;
  color: #20a8d8 !important;

  .ng-value-icon {
    border-right: 1px solid #181b1e !important;
  }
}
.ng-dropdown-panel {
  border-color: currentcolor #23282c #23282c;
  border-top: #23282c;
}
.ng-option {
  background-color: #515b65 !important;
  border: 1px solid #454e57 !important;
  color: #e4e7ea !important;

  &.ng-option-marked {
    color: #343b41 !important;
    text-decoration: none;
    background-color: #20a8d8 !important;
  }
  &.ng-option-selected {
    background-color: #454e57 !important;
    color: #63c2de !important;
  }
  &.ng-option-disabled {
    color: #ccc !important;
    background-color: #73818f !important;
  }
}
.client-auto .ng-value {
  color: #e4e7ea !important;
  background-color: #515b65 !important;
  border-color: #515b65 !important;
}
//Header
app-header {
  background-color: #f3f4f5 !important;
  color: #000;
}

.custom-fixed-top {
  background-color: #2f353a;
}

// Quill editor
.ql-container.ql-snow {
  border: 1px solid #23282c !important;
  background-color: #515b65;
}

.ql-toolbar.ql-snow {
  border: 1px solid #23282c !important;
}

.ql-editor {
  color: white;
  height: 300px !important;
}

// Calendar
.cal-day-number {
  color: white;
  opacity: 1 !important;
}

.cal-cell-row:hover {
  background-color: #2f353a !important;
  color: white;
}

.cal-cell:hover {
  background-color: #334354 !important;
  color: white;
}

.cal-cell.cal-has-events.cal-open {
  background-color: #334354 !important;
}

.cal-month-view .cal-today {
  background-color: #73818f !important;
}

.cal-out-month {
  opacity: 0.5 !important;
}

.cal-weekend .cal-day-number {
  color: #2da8d8 !important;
}

.cal-hour-odd {
  background-color: #2f353a !important;
}

.cal-hour-segment:hover {
  background-color: #334354 !important;
  color: white;
}

a.cal-event-action {
  color: white !important;
}

.cal-weekend span {
  color: #2da8d8 !important;
}

.cal-hour:nth-child(odd) {
  background-color: #2f353a !important;
}

.cal-header.cal-today {
  background-color: transparent !important;
}

.cal-header:hover {
  background-color: transparent !important;
}

.bg-theme {
  background-color: #2f353a !important;
}

.option-label {
  background-color: #2f353a;
}

.dropdown-item.unreaded {
  background-color: #3a4149;
}
.dropdown-item.readed {
  background-color: #2f353a;
}
