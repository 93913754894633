// Here you can add other styles

// Menu icons colors
.menu-icon-dashboard i {
  color: #63c2de !important;
}

.menu-icon-work-orders i {
  color: #6f42c1 !important;
}

.menu-icon-requests i {
  color: #e83e8c !important;
}

.menu-icon-calendar i {
  color: #f86c6b !important;
}

.menu-icon-clients i {
  color: #ffc107 !important;
}

.menu-icon-bids i {
  color: #20c997 !important;
}

.menu-icon-reports i {
  color: #17a2b8 !important;
}

.menu-icon-users i {
  color: #f8cb00 !important;
}

.menu-icon-settings i {
  color: #20a8d8 !important;
}

.menu-icon-attch i {
  color: #8280ff !important;
}

// Background colors
.bg-pink {
  background-color: #aa6e15 !important;
}
.bg-violet {
  background-color: #6f42c1 !important;
}
.bg-cyan {
  background-color: #20c997 !important;
}
.bg-slate-blue {
  background-color: #8280ff !important;
}
.bg-orange-500 {
  background-color: #ffa500 !important;
}
.bg-marigold {
  background-color: #bb8b44 !important;
}
.bg-pink,
.bg-violet,
.bg-cyan,
.bg-slate-blue,
.bg-orange-500,
.bg-marigold {
  color: #fff !important;
}

// Required fields
.form-group.required .control-label:after {
  content: "*";
  color: red;
}
fieldset {
  ng-select.is-invalid {
    border: 1px solid #f86c6b !important;
  }
  .invalid-feedback {
    display: block;
  }
}

// Thank you page styles
body.plan-basic {
  background-color: #20c997 !important;
}
body.plan-advance {
  background-color: #3b86ff !important;
}
body.plan-professional {
  background-color: #43425d !important;
}
//Cursor pointer
.pointer {
  cursor: pointer;
}
a:hover {
  text-decoration: none !important;
}
// Header
.center-initials {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fdfefe;
}
.nav.navbar-nav.ml-auto {
  margin-right: 15px;
}
// .nav.navbar-nav.ml-auto ~ button.navbar-toggler {
//   display: none !important;
// }
app-header {
  height: 100px !important;

  + * {
    margin-top: 100px !important;
  }
}
app-register {
  background-color: #2f353a !important;
}
@media (max-width: 991.98px) {
  .app-header .navbar-brand {
    position: relative !important;
    left: 40% !important;
  }
}
@media (max-width: 420px) {
  .app-header .navbar-brand {
    left: 30% !important;
  }
}
@media (max-width: 365px) {
  .app-header .navbar-brand {
    display: none !important;
  }
}
//Datepicker
.custom-today-class {
  background-color: rgba(32, 168, 216, 0.5);
}

.app-body {
  overflow: hidden;
}
.right {
  float: right;
}

#time-picker-wrapper {
  z-index: 1050 !important;
}

ngx-material-timepicker-container {
  z-index: 1050;
}

// Fixed header
.custom-fixed-top {
  width: 100%;
  align-content: center;
  position: fixed;
  z-index: 1030;
  height: auto;
  min-height: 100px;
  padding-bottom: 1rem;
  left: auto;

  &.top-100 {
    top: 100px;
  }
  &.top-10 {
    top: 0;
  }
  // &.right-215 {
  //   padding-right: 215px;
  // }

  .sticky-tabset {
    display: block;

    .nav-tabs {
      border-bottom: 0;
    }

    .tab-content {
      display: none;
    }
  }
}
// App invoice
@media (min-width: 1440px) {
  .container.invoice {
    max-width: 34%;
  }
}
@media (min-width: 1024px) and (max-width: 1339px) {
  .container.invoice {
    max-width: 50%;
  }
}
.payment-success .alert.alert-success {
  padding: 4.25rem 1.25rem;
}

// Icons
.options i {
  font-size: 2rem;
}
.text-back {
  color: #73818f;
}

// Dropdown
.dropdown-item.unreaded,
.dropdown-item.readed {
  padding-right: 5px !important;
}

map-view {
  height: 700px;
  width: 100%;
}
.font-bold {
  font-weight: bold;
}
p.link {
  color: #167495;
}
p.link:hover {
  text-decoration: underline;
}

.modal-auto {
  max-width: fit-content !important;
}

// Aligns
.self-center {
  align-self: center;
}

.shape-circle {
  border-radius: 9999px;
  height: 2rem;
  width: 2rem;
  display: flex;

  & input[type="checkbox"] {
    position: absolute;
    opacity: 1;
    height: 1.5rem;
    width: 1.5rem;
    cursor: pointer;
    appearance: none;
  }
}

.cdk-overlay-container {
  z-index: 1051 !important;
}
