// If you want to override variables do it here
@import "variables";

// Import styles with default layout.
// If you are going to use dark layout please comment next line
// @import "~@coreui/coreui-pro/scss/coreui";

// Import styles with dark layout
// If you want to use dark layout uncomment next line
// @import "~@coreui/coreui-pro/scss/themes/dark/coreui-dark";
@import "themes/dark.scss";

// If you want to add something do it here
@import "custom";
@import "colors";

// Spinkit
// $spinkit-spinner-color: $body-color;
// @import '~spinkit/scss/spinkit.scss';

//BsDatepicker
@import "vendors/bs-datepicker/bs-datepicker.scss";

@import "~@ng-select/ng-select/themes/default.theme.css";

@import "~@fortawesome/fontawesome-free/css/all.css";
// @import "vendors/angular-calendar/angular-calendar.scss"
@import "~quill/dist/quill.bubble.css";
@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import "vendors/chart.js/chart.scss";
